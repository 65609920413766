import { useEffect, useState } from 'react'
import { useContext } from 'react'
import { store } from '../../../store'
import Toaster from '../../Toaster'
import Loader from '../../Loader/Loader'
import axios from 'axios'

const AddSingleOrder = ({ setAddOrder, amaps, orders, setOrders }) => {
	const globalContext = useContext(store)
	const { dispatch } = globalContext
	const { loading, user, products, selectedMonth, message, messageType } =
		globalContext.state

	const [name, setName] = useState('')
	const [surname, setSurname] = useState('')
	const [amap, setAmap] = useState('')
	const [details, setDetails] = useState([])

	const slugify = (string) => {
		return string
			.toString()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase()
			.replace(/\s+/g, '-')
			.replace(/[^\w-]+/g, '')
			.replace(/--+/g, '-')
			.replace(/^-+/, '')
			.replace(/-+$/, '')
	}

	const displayDate = (isoDateTime) => {
		const date = new Date(Date.parse(isoDateTime))
		return date.toLocaleString('fr-FR', {
			month: 'long',
			year: 'numeric',
		})
	}

	const setQuantity = (product, quantity) => {
		let newDetails = [...details]
		let detail = newDetails.filter(
			(detail) => detail.product._id === product._id
		)
		if (detail.length > 0) {
			const detailIndex = details.findIndex(
				(detail) => detail.product._id === product._id
			)
			newDetails[detailIndex].quantity = quantity
		} else {
			newDetails.push({ product, quantity })
		}
		setDetails(newDetails)
	}

	const checkIfOrderRecapexist = async (amapId) => {
		const selectedSession =
			selectedMonth.getFullYear().toString() +
			('0' + (selectedMonth.getMonth() + 1)).slice(-2)
		dispatch({ type: 'LOADING' })
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
			await axios.post(
				`${process.env.REACT_APP_API_URL}/api/orders/recaps/update`,
				{ amap: amapId, session: selectedSession },
				config
			)
			setAmap(amapId)
			dispatch({ type: 'FINISHED_LOADING' })
		} catch (error) {
			dispatch({
				type: 'MESSAGE',
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
				messageType: 'error',
			})
			setAddOrder(false)
			dispatch({ type: 'FINISHED_LOADING' })
		}
	}

	const sendOrder = async () => {
		const zeroQuantities = details.filter(
			(detail) => parseInt(detail.quantity) !== 0
		)
		if (zeroQuantities.length === 0) {
			dispatch({
				type: 'MESSAGE',
				messageType: 'warning',
				payload: "Il n'y a aucun produit dans cette commande...",
			})
			return
		}
		const selectedSession =
			selectedMonth.getFullYear().toString() +
			('0' + (selectedMonth.getMonth() + 1)).slice(-2)
		dispatch({ type: 'LOADING' })
		let detailsToSend = []
		details.forEach((detail) => {
			detailsToSend.push({
				product: detail.product._id,
				quantity: detail.quantity,
			})
		})
		const body = {
			clientSlug: slugify(amap + '-' + name + surname),
			clientName: `${name} ${surname}`,
			details: detailsToSend,
			amap: amap,
			session: selectedSession,
		}
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}

			const { data } = await axios.post(
				`${process.env.REACT_APP_API_URL}/api/orders/backoffice`,
				body,
				config
			)

			dispatch({
				type: 'MESSAGE',
				payload: 'Commande enregistrée avec succès !',
				messageType: 'success',
			})
			setOrders([...orders, data])
			setAddOrder(false)
			dispatch({ type: 'FINISHED_LOADING' })
		} catch (error) {
			dispatch({
				type: 'MESSAGE',
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
				messageType: 'error',
			})
			dispatch({ type: 'FINISHED_LOADING' })
		}
	}

	useEffect(() => {
		let initialDetails = []
		products.map((product) => {
			return initialDetails.push({ product, quantity: 0 })
		})
		setDetails(initialDetails)
	}, [products])

	return (
		<div className='flex column container' style={{ marginTop: '1em' }}>
			{message && <Toaster message={message} type={messageType} />}
			{loading && <Loader />}
			<h1 style={{ marginBottom: '0' }}>Nouvelle commande</h1>
			<h3 style={{ textAlign: 'center' }}>
				{displayDate(selectedMonth)}
			</h3>
			<form
				className='flex'
				style={{
					maxWidth: 'fit-content',
					margin: '0 auto',
					justifyContent: 'space-around',
				}}
			>
				<h3 style={{ margin: 'auto' }}>* Amap :</h3>
				<select
					name='amap'
					required
					style={{
						margin: 'auto',
						border: 'none',
						maxWidth: '60%',
					}}
					value={amap}
					onChange={(e) => {
						checkIfOrderRecapexist(e.target.value)
					}}
				>
					<option value='' disabled>
						Choisir une amap
					</option>
					{amaps.map((amap) => {
						return (
							<option value={amap._id} key={amap._id}>
								{amap.name}
							</option>
						)
					})}
				</select>
			</form>
			<div className='flex column'>
				<form
					className='flex'
					style={{
						maxWidth: '900px',
						margin: 'auto',
						gap: '2em',
						justifyContent: 'center',
					}}
				>
					<div className='field'>
						<input
							type='text'
							name='name'
							className='input'
							placeholder=''
							required
							onChange={(e) => setName(e.target.value)}
						/>
						<label htmlFor='name' className='label'>
							* Nom
						</label>
					</div>
					<div className='field'>
						<input
							type='text'
							name='surname'
							className='input'
							placeholder=''
							required
							onChange={(e) => setSurname(e.target.value)}
						/>
						<label htmlFor='name' className='label'>
							* Prénom
						</label>
					</div>
				</form>
				<form
					className='flex'
					style={{ maxWidth: '900px', margin: '0 auto', gap: '2em' }}
				>
					{products.map((product) => {
						const detailToDisplay = details.filter(
							(detail) => detail.product._id === product._id
						)
						if (detailToDisplay.length !== 0) {
							return (
								<div
									className='field'
									key={detailToDisplay[0].product._id}
								>
									<input
										type='number'
										step='1'
										min={0}
										name={detailToDisplay[0].product.title}
										className='input'
										value={detailToDisplay[0].quantity}
										autoComplete='off'
										onChange={(e) =>
											setQuantity(
												detailToDisplay[0].product,
												e.target.value
											)
										}
									/>
									<label
										htmlFor={
											detailToDisplay[0].product.title
										}
										className='label'
									>
										{detailToDisplay[0].product.title}
									</label>
								</div>
							)
						} else {
							return (
								<div
									className='field'
									key={`new-${product._id}-In-newOrder`}
								>
									<input
										type='number'
										step='1'
										min={0}
										name={product.title}
										className='input'
										value='0'
										autoComplete='off'
										onChange={(e) =>
											setQuantity(product, e.target.value)
										}
									/>
									<label
										htmlFor={product.title}
										className='label'
									>
										{product.title}
									</label>
								</div>
							)
						}
					})}
				</form>
				<div
					className='flex'
					style={{
						maxWidth: '450px',
						margin: '0 auto',
						gap: '5em',
						justifyContent: 'space-evenly',
						marginBottom: 'auto',
					}}
				>
					<button
						className='button danger'
						style={{ border: 'none' }}
						onClick={() => setAddOrder(false)}
					>
						ANNULER
					</button>
					<button className='button' onClick={() => sendOrder()}>
						ENREGISTRER
					</button>
				</div>
			</div>
		</div>
	)
}

export default AddSingleOrder
