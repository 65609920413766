import { useContext, useEffect, useState, useMemo } from 'react'
import { store } from '../../../store'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

import Toaster from '../../Toaster'
import Loader from '../../Loader/Loader'
import OrdersTable from './OrdersTable'

import {
	BiSearchAlt2,
	BiX,
	BiChevronLeft,
	BiChevronRight,
	BiListPlus,
	BiAddToQueue,
} from 'react-icons/bi'

import './orders.css'
import AddSingleOrder from './AddSingleOrder'
import AddBatchOrders from './AddBatchOrders'

const Orders = () => {
	const globalContext = useContext(store)
	const { dispatch } = globalContext
	const { user, message, messageType, loading, products, selectedMonth } =
		globalContext.state
	const { search: searchParams } = useLocation()
	const params = useMemo(
		() => new URLSearchParams(searchParams),
		[searchParams]
	)

	const [orders, setOrders] = useState([])
	const [addOrder, setAddOrder] = useState(false)
	const [addBatchOrder, setAddBatchOrder] = useState(false)

	const [amaps, setAmaps] = useState([])
	const [amap, setAmap] = useState(params.get('amapId') || '')
	const [tempName, setTempName] = useState('')
	const [clientName, setClientName] = useState('')

	const search = (e) => {
		e.preventDefault()
		setClientName(tempName)
	}
	const resetSearch = (e) => {
		e.preventDefault()
		setTempName('')
		setClientName('')
	}

	const decrementDate = () => {
		dispatch({
			type: 'SET_SELECTED_MONTH',
			payload: new Date(
				selectedMonth.setMonth(selectedMonth.getMonth() - 1)
			),
		})
	}
	const incrementDate = () => {
		dispatch({
			type: 'SET_SELECTED_MONTH',
			payload: new Date(
				selectedMonth.setMonth(selectedMonth.getMonth() + 1)
			),
		})
	}

	useEffect(() => {
		const getAmaps = async () => {
			dispatch({ type: 'LOADING' })
			try {
				const config = {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
				const { data } = await axios.get(
					`${process.env.REACT_APP_API_URL}/api/amaps`,
					config
				)
				dispatch({ type: 'FINISHED_LOADING' })
				setAmaps(data)
			} catch (error) {
				dispatch({
					type: 'MESSAGE',
					payload:
						error.response && error.response.data.message
							? error.response.data.message
							: error.message,
					messageType: 'error',
				})
				dispatch({ type: 'FINISHED_LOADING' })
			}
		}
		const getOrdersBySession = async () => {
			dispatch({ type: 'LOADING' })
			const selectedSession =
				params.get('session') ||
				selectedMonth.getFullYear().toString() +
					('0' + (selectedMonth.getMonth() + 1)).slice(-2)
			try {
				const config = {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
				const { data } = await axios.get(
					`${process.env.REACT_APP_API_URL}/api/orders?session=${selectedSession}&clientName=${clientName}&amap=${amap}`,
					config
				)
				setOrders(data.allOrders)
				dispatch({ type: 'FINISHED_LOADING' })
			} catch (error) {
				dispatch({
					type: 'MESSAGE',
					payload:
						error.response && error.response.data.message
							? error.response.data.message
							: error.message,
					messageType: 'error',
				})
			}
		}
		getAmaps()
		getOrdersBySession()
	}, [dispatch, user.token, selectedMonth, clientName, amap, params])

	if (addOrder) {
		return (
			<AddSingleOrder
				setAddOrder={setAddOrder}
				amaps={amaps}
				orders={orders}
				setOrders={setOrders}
			/>
		)
	}

	if (addBatchOrder) {
		return (
			<AddBatchOrders
				amaps={amaps}
				setAddBatchOrder={setAddBatchOrder}
				orders={orders}
				setOrders={setOrders}
			/>
		)
	}

	return (
		<div className='flex column container'>
			<div className='flex' style={{ justifyContent: 'space-between' }}>
				<h1 className='printHeader'>
					Commandes - juju2fruits -{' '}
					{selectedMonth.toLocaleDateString('fr-FR', {
						month: 'long',
						year: 'numeric',
					})}
					<br />
					{amap.length > 0 &&
						orders.length > 0 &&
						amaps.filter((filterAmap) => filterAmap._id === amap)[0]
							.name}
				</h1>
				<div
					className='flex column orderSearch'
					style={{ justifyContent: 'center' }}
				>
					<form className='flex searchForm'>
						<button
							onClick={(e) => search(e)}
							className='searchButton'
						>
							<BiSearchAlt2 />
						</button>
						<input
							type='text'
							name='search'
							placeholder='Rechercher par nom'
							value={tempName}
							onChange={(e) => setTempName(e.target.value)}
							style={{ margin: 'auto', border: 'none' }}
						/>
						<button
							className='resetSearch'
							onClick={(e) => resetSearch(e)}
						>
							<BiX />
						</button>
					</form>
					<form
						className='flex searchForm'
						style={{
							width: '100%',
							justifyContent: 'space-around',
						}}
					>
						<p style={{ margin: 'auto' }}>Amap :</p>
						<select
							name='amap'
							style={{
								margin: 'auto',
								border: 'none',
								maxWidth: '60%',
							}}
							value={amap}
							onChange={(e) => setAmap(e.target.value)}
						>
							<option value=''>Toutes</option>
							{amaps.map((amap) => {
								return (
									<option value={amap._id} key={amap._id}>
										{amap.name}
									</option>
								)
							})}
						</select>
					</form>
				</div>
				<div>
					<h1 className='webHeader'>Commandes</h1>
				</div>

				<div
					className='flex column noPrint'
					style={{ justifyContent: 'center' }}
				>
					<div className='monthSelect'>
						<BiChevronLeft
							className='changeDate'
							onClick={decrementDate}
						/>
						{selectedMonth.toLocaleDateString('fr-FR', {
							month: 'long',
							year: 'numeric',
						})}
						<BiChevronRight
							className='changeDate'
							onClick={incrementDate}
						/>
					</div>
					<div
						className='flex'
						style={{
							justifyContent: 'start',
							gap: '1em',
							marginTop: '0.5em',
						}}
					>
						<button
							className='action flex'
							style={{
								fontSize: 'unset',
								gap: '5px',
								border: '1px solid black',
								background: 'none',
							}}
							onClick={() => setAddOrder(true)}
						>
							<BiAddToQueue />
							Ajouter 1
						</button>
						<button
							className='action flex'
							style={{
								fontSize: 'unset',
								gap: '5px',
								border: '1px solid black',
								background: 'none',
							}}
							onClick={() => {
								setAddBatchOrder(true)
							}}
						>
							<BiListPlus /> Ajouter liste
						</button>
					</div>
				</div>
			</div>
			{message && <Toaster message={message} type={messageType} />}
			{loading ? (
				<Loader />
			) : (
				<>
					{orders && orders.length !== 0 ? (
						<OrdersTable
							products={products}
							orders={orders}
							setOrders={setOrders}
						/>
					) : (
						<p style={{ textAlign: 'center' }}>
							Aucune Commande trouvée...
						</p>
					)}
				</>
			)}
		</div>
	)
}

export default Orders
