import { useState, useContext } from 'react'
import axios from 'axios'
import EditOrder from './EditOrder'
import { store } from '../../../store'

import { BiEdit, BiTrash, BiCheckCircle, BiMinusCircle } from 'react-icons/bi'

const OrdersTable = ({ products, orders, setOrders }) => {
	const globalContext = useContext(store)
	const { dispatch } = globalContext
	const { user } = globalContext.state

	const [displayModal, setDisplayModal] = useState(false)
	const [orderToEdit, setOrderToEdit] = useState({})

	const getOrderTotal = (total, detail) => {
		return total + detail.quantity * detail.product.pricePerKg
	}

	const getProductTotal = (id) => {
		let total = 0
		orders.forEach((order) => {
			order.details.forEach((detail) => {
				if (detail.product._id === id) {
					total += detail.quantity
				}
			})
		})
		return total
	}

	const getOrdersTotal = () => {
		let total = 0
		orders.forEach((order) => {
			total += order.details.reduce(getOrderTotal, 0)
		})
		return total.toFixed(2)
	}

	const editOrder = (index, order) => {
		order.index = index
		setOrderToEdit(order)
		setDisplayModal(true)
	}

	const deleteOrder = async (orderId) => {
		if (window.confirm('Es-tu sûr de vouloir supprimer cette commande ?')) {
			dispatch({ type: 'LOADING' })
			let newArrayOfOrders = orders.filter((order) => {
				return order._id !== orderId
			})
			try {
				const config = {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
				const { data } = await axios.delete(
					`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`,
					config
				)
				dispatch({
					type: 'MESSAGE',
					payload: data.message,
					messageType: 'success',
				})
				setOrders(newArrayOfOrders)
				dispatch({ type: 'FINISHED_LOADING' })
			} catch (error) {
				dispatch({
					type: 'MESSAGE',
					payload:
						error.response && error.response.data.message
							? error.response.data.message
							: error.message,
					messageType: 'error',
				})
				dispatch({ type: 'FINISHED_LOADING' })
			}
		}
	}

	const changePaidStatus = async (index) => {
		const orderToUpdate = orders[index]
		orderToUpdate.paid = !orderToUpdate.paid
		try {
			dispatch({ type: 'LOADING' })
			const config = {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
			await axios.put(
				`${process.env.REACT_APP_API_URL}/api/orders`,
				{
					order: {
						_id: orderToUpdate._id,
						client: orderToUpdate.client,
						details: orderToUpdate.details,
						amap: orderToUpdate.amap,
						session: orderToUpdate.session,
						paid: orderToUpdate.paid,
					},
				},
				config
			)
			dispatch({ type: 'FINISHED_LOADING' })
		} catch (error) {
			dispatch({
				type: 'MESSAGE',
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
				messageType: 'error',
			})
		}
	}

	return (
		<>
			{displayModal && (
				<EditOrder
					order={orderToEdit}
					setDisplayModal={setDisplayModal}
					orders={orders}
					setOrders={setOrders}
				/>
			)}
			<table
				style={{
					width: 'fit-content',
					padding: '20px 0',
					margin: '0 auto',
				}}
			>
				<thead>
					<tr>
						<th className='amapOrderColumn'>Amap</th>
						<th>Nom</th>
						{products.map((product) => {
							return (
								<th key={product._id}>
									<b>{product.title}</b>
									<br />
									<i>{product.pricePerKg.toFixed(2)}</i>
								</th>
							)
						})}
						<th>Total</th>
						<th>Payé ?</th>
					</tr>
				</thead>
				<tbody>
					{orders.map((order, index) => {
						return (
							<tr key={order._id}>
								<td
									className='amapOrderColumn'
									style={{
										backgroundColor: order.amap.color,
									}}
								>
									<b>{order.amap.name}</b>
								</td>

								<td>
									<a href={`/clients/${order.client._id}`}>
										{order.client.name}
									</a>
								</td>

								{products.map((product) => {
									const detailToDisplay =
										order.details.filter(
											(detail) =>
												detail.product._id ===
												product._id
										)

									if (detailToDisplay.length !== 0) {
										return (
											<td key={detailToDisplay[0]._id}>
												{detailToDisplay[0].quantity}
											</td>
										)
									} else {
										return (
											<td
												key={`${product._id}-notIn-${order._id}`}
											>
												0
											</td>
										)
									}
								})}

								<td>
									<b>
										{order.details
											.reduce(getOrderTotal, 0)
											.toFixed(2)}
										&nbsp;€
									</b>
								</td>
								<td
									className={
										order.paid ? 'available' : 'unavailable'
									}
									style={{
										textAlign: 'center',
										fontSize: '1.5em',
									}}
								>
									{order.paid ? (
										<BiCheckCircle
											className='paidStatus'
											style={{
												display: 'block',
												margin: 'auto',
											}}
											onClick={() =>
												changePaidStatus(index)
											}
										/>
									) : (
										<BiMinusCircle
											className='paidStatus notPaid'
											style={{
												display: 'block',
												margin: 'auto',
											}}
											onClick={() =>
												changePaidStatus(index)
											}
										/>
									)}
								</td>
								<td className='rowEnd'>
									<BiEdit
										className='action'
										onClick={() => {
											editOrder(index, order)
										}}
									/>
								</td>
								<td className='rowEnd'>
									<BiTrash
										className='action'
										onClick={() => {
											deleteOrder(order._id)
										}}
									/>
								</td>
							</tr>
						)
					})}
					<tr>
						<td colSpan={2}>
							<b>Récap</b>
						</td>
						{products.map((product) => {
							return (
								<td key={product._id}>
									{getProductTotal(product._id)}
								</td>
							)
						})}
						<td>
							<b>{getOrdersTotal()} €</b>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	)
}

export default OrdersTable
