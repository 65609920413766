import { useEffect, useState } from 'react'
import { useContext } from 'react'
import { store } from '../../../store'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Toaster from '../../Toaster'
import Loader from '../../Loader/Loader'
import Pagination from '../../Pagination'

import './users.css'

import {
	BiEdit,
	BiTrash,
	BiCheckCircle,
	BiMinusCircle,
	BiSearchAlt2,
	BiX,
	BiUserCircle,
} from 'react-icons/bi'
import { BsCheck2All } from 'react-icons/bs'

const Users = () => {
	const navigate = useNavigate()
	const globalContext = useContext(store)
	const { dispatch } = globalContext
	const { user, message, messageType, loading } = globalContext.state
	const [users, setUsers] = useState([])
	const [tempKeyword, setTempKeyword] = useState('')
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(1)
	const [pages, setPages] = useState(1)
	const [userGroup, setUserGroup] = useState('frontend')
	const [selectAll, setSelectAll] = useState(false)

	const search = (e) => {
		e.preventDefault()
		setKeyword(tempKeyword)
	}
	const resetSearch = (e) => {
		e.preventDefault()
		setTempKeyword('')
		setKeyword('')
	}

	const deleteUsers = async (userToDelete) => {
		const usersToDelete = users.filter((user) => user.selected)
		if (usersToDelete.length === 0) {
			dispatch({
				type: 'MESSAGE',
				payload: "Aucun utilisateur n'est sélectionné...",
				messageType: 'warning',
			})
			return
		}
		if (
			window.confirm(
				`Es-tu sûr de vouloir supprimer le / les profil(s) utilisateur(s) selectionnés,\nainsi que toutes les commandes lièes ?\nCeci est irréversible...`
			)
		) {
			dispatch({ type: 'LOADING' })
			let newArrayOfUsers = users.filter((user) => !user.selected)
			try {
				const config = {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
				const { data } = await axios.post(
					`${process.env.REACT_APP_API_URL}/api/users/deleteMany`,
					{ usersToDelete },
					config
				)
				dispatch({
					type: 'MESSAGE',
					payload: data.message,
					messageType: 'success',
				})
				setUsers(newArrayOfUsers)
				setSelectAll(false)
				dispatch({ type: 'FINISHED_LOADING' })
			} catch (error) {
				dispatch({
					type: 'MESSAGE',
					payload:
						error.response && error.response.data.message
							? error.response.data.message
							: error.message,
					messageType: 'error',
				})
				dispatch({ type: 'FINISHED_LOADING' })
			}
		}
	}

	const toggleSelectAllUsers = () => {
		setUsers(
			users.map((user) => {
				user.selected = !selectAll
				return { ...user }
			})
		)
		setSelectAll(!selectAll)
	}

	const toggleOne = (userId, checked) => {
		setUsers(
			users.map((user) => {
				if (user._id === userId) {
					user.selected = checked
				}
				return { ...user }
			})
		)
	}

	useEffect(() => {
		dispatch({ type: 'LOADING' })
		const config = {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		}
		const getUsers = async () => {
			dispatch({ type: 'LOADING' })
			try {
				const { data } = await axios.get(
					`${process.env.REACT_APP_API_URL}/api/users?keyword=${keyword}&pageNumber=${pageNumber}&userGroup=${userGroup}`,
					config
				)
				setPageNumber(data.page)
				setPages(data.pages)
				setUsers(data.users)
				dispatch({ type: 'FINISHED_LOADING' })
			} catch (error) {
				dispatch({
					type: 'MESSAGE',
					payload:
						error.response && error.response.data.message
							? error.response.data.message
							: error.message,
					messageType: 'error',
				})
				dispatch({ type: 'FINISHED_LOADING' })
			}
		}
		getUsers()
	}, [dispatch, user.token, keyword, pageNumber, userGroup])

	return (
		<div className='flex column container'>
			{message && <Toaster message={message} type={messageType} />}
			{loading ? (
				<Loader />
			) : (
				<>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-evenly',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '2em',
							}}
						>
							{userGroup === 'frontend' ? (
								<button
									onClick={() => setUserGroup('backoffice')}
									className='action'
									style={{
										background: 'none',
										gap: '10px',
										border: '1px solid black',
									}}
								>
									<BiUserCircle
										style={{ fontSize: '1.5em' }}
									/>{' '}
									Utilisateurs Back-office
								</button>
							) : (
								<button
									onClick={() => setUserGroup('frontend')}
									className='action'
									style={{
										background: 'none',
										gap: '10px',
										border: '1px solid black',
									}}
								>
									<BiUserCircle
										style={{ fontSize: '1.5em' }}
									/>
									Utilisateurs publiques
								</button>
							)}
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									gap: '2em',
								}}
								onClick={deleteUsers}
							>
								<button className='danger delete'>
									<BiTrash style={{ fontSize: '1.5em' }} />
									Supprimer
								</button>
							</div>
						</div>
						<h1 style={{ flexGrow: 'grow' }}>
							Utilisateurs{' '}
							{userGroup === 'frontend'
								? 'publiques'
								: 'back-office'}
						</h1>
						<div className='userSearch'>
							<form className='flex searchForm'>
								<button
									onClick={(e) => search(e)}
									className='searchButton'
								>
									<BiSearchAlt2 />
								</button>
								<input
									type='text'
									name='search'
									placeholder='Nom / Email'
									value={tempKeyword}
									onChange={(e) =>
										setTempKeyword(e.target.value)
									}
									style={{ margin: 'auto', border: 'none' }}
								/>
								<button
									className='resetSearch'
									onClick={(e) => resetSearch(e)}
								>
									<BiX />
								</button>
							</form>
						</div>
					</div>
					<table>
						<thead>
							<tr>
								<th
									style={{
										border: 'none',
									}}
								>
									<div
										style={{
											cursor: 'pointer',
											display: 'flex',
											justifyContent: 'end',
											border: '1px solid black',
											borderRadius: '3px',
											maxWidth: 'max-content',
											marginLeft: 'auto',
											padding: '0 0 0 5px',
										}}
										onClick={toggleSelectAllUsers}
									>
										<label
											htmlFor='all'
											style={{
												maxWidth: '100px',
												fontSize: '20px',
												pointerEvents: 'none',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<BsCheck2All />
										</label>
										<input
											type='checkbox'
											name='all'
											id=''
											checked={selectAll}
											style={{ pointerEvents: 'none' }}
										/>
									</div>
								</th>
								<th>Nom</th>
								{userGroup === 'frontend' && <th>Email</th>}
								<th>Groupement</th>
								<th>Amap</th>
								<th>Admin</th>
								<th>Date de création</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user) => {
								return (
									<tr key={user._id}>
										<td
											style={{
												borderLeft: 'none',
												borderTop: 'none',
												borderBottom: 'none',
												textAlign: 'end',
											}}
										>
											<input
												type='checkbox'
												name={user.name}
												value={user._id}
												checked={user.selected || false}
												onChange={(e) =>
													toggleOne(
														user._id,
														e.target.checked
													)
												}
											/>
										</td>
										<td>{user.name}</td>
										{userGroup === 'frontend' && (
											<td>{user.email}</td>
										)}
										<td>
											{user.amap
												? user.amap.groupement
												: ''}
										</td>
										<td>
											{user.amap ? user.amap.name : ''}
										</td>
										<td
											className={
												user.isAdmin
													? 'available'
													: 'unavailable'
											}
											style={{
												textAlign: 'center',
												fontSize: '1.5em',
											}}
										>
											{user.isAdmin ? (
												<BiCheckCircle
													style={{
														display: 'block',
														margin: 'auto',
													}}
												/>
											) : (
												<BiMinusCircle
													style={{
														display: 'block',
														margin: 'auto',
													}}
												/>
											)}
										</td>
										<td>
											{new Date(
												user.createdAt
											).toLocaleDateString('fr-FR', {
												day: 'numeric',
												month: 'numeric',
												year: 'numeric',
											})}
										</td>
										<td className='rowEnd'>
											<BiEdit
												className='action'
												onClick={() => {
													navigate(
														`/clients/${user._id}`
													)
												}}
											/>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					<Pagination
						pages={pages}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
					/>
				</>
			)}
		</div>
	)
}

export default Users
