import { useState } from 'react'

const SingleLabel = ({ order, products }) => {
	const getOrderTotal = (total, detail) => {
		return total + detail.quantity * detail.product.pricePerKg
	}
	const getRecapTotalWeight = (total, product) => {
		if (product.product.title.toLowerCase() !== 'mangues') {
			return total + product.quantity
		} else {
			return total
		}
	}
	const [totalWeight] = useState(order.details.reduce(getRecapTotalWeight, 0))
	const numberOfLabels = []
	for (
		let index = 0;
		index < Math.floor((totalWeight - 1) / 10) + 1;
		index++
	) {
		numberOfLabels.push(index + 1)
	}

	return numberOfLabels.map((labelIndex) => (
		<table className='etiquette' key={`${order._id}-${labelIndex}`}>
			<tbody>
				<tr>
					<th
						colSpan={order.details.length}
						style={{
							backgroundColor: order.amap.color,
						}}
					>
						{order.client.name}
					</th>
					<td>{order.paid ? 'P' : 'NP'}</td>
				</tr>

				<tr>
					{order.details.map((detail) => {
						return (
							products.filter(
								(product) => detail.product._id === product._id
							)[0].isAvailable && (
								<td key={`title-${detail._id}`}>
									{detail.product.title.toLowerCase() ===
									'mangues'
										? 'Mg'
										: detail.product.title.toLowerCase() ===
										  'mandarines'
										? 'Md'
										: detail.product.title.substring(0, 2)}
								</td>
							)
						)
					})}
					<td>{order.details.reduce(getOrderTotal, 0).toFixed(2)}</td>
					{numberOfLabels.length > 1 && (
						<td rowSpan={2}>
							<b>{labelIndex}</b>
						</td>
					)}
				</tr>
				<tr>
					{order.details.map((detail) => {
						return (
							products.filter(
								(product) => detail.product._id === product._id
							)[0].isAvailable && (
								<td key={`qty-${detail._id}`}>
									{detail.quantity}
								</td>
							)
						)
					})}
					<td>{order.details.reduce(getRecapTotalWeight, 0)}</td>
				</tr>
			</tbody>
		</table>
	))
}

export default SingleLabel
