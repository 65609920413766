import { useContext, useState } from 'react'
import { store } from '../../../store'
import Toaster from '../../Toaster'
import Loader from '../../Loader/Loader'
import axios from 'axios'
import { useCSVReader } from 'react-papaparse'
import { BiInfoCircle } from 'react-icons/bi'
import { GrFormClose } from 'react-icons/gr'

const AddBatchOrders = ({ amaps, setAddBatchOrder, orders, setOrders }) => {
	const globalContext = useContext(store)
	const { dispatch } = globalContext
	const { loading, user, products, selectedMonth, message, messageType } =
		globalContext.state

	const [amap, setAmap] = useState('')
	const [data, setData] = useState([])
	const [totals, setTotals] = useState()
	const [sending, setSending] = useState(false)
	const [progress, setProgress] = useState(0)
	const [displayInfo, setDisplayInfo] = useState(false)
	const { CSVReader } = useCSVReader()

	const getTotals = (input) => {
		let totalAvocats = 0
		let totalCitrons = 0
		let totalMandarines = 0
		let totalMangues = 0
		let totalOranges = 0
		let totalPomelos = 0
		input.forEach((row) => {
			totalAvocats += parseInt(row.avocats) || 0
			totalCitrons += parseInt(row.citrons) || 0
			totalMandarines += parseInt(row.mandarines) || 0
			totalMangues += parseInt(row.mangues) || 0
			totalOranges += parseInt(row.oranges) || 0
			totalPomelos += parseInt(row.pomelos) || 0
		})
		return {
			avocats: totalAvocats,
			citrons: totalCitrons,
			mandarines: totalMandarines,
			mangues: totalMangues,
			oranges: totalOranges,
			pomelos: totalPomelos,
		}
	}

	const slugify = (string) => {
		return string
			.toString()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase()
			.replace(/\s+/g, '-')
			.replace(/[^\w-]+/g, '')
			.replace(/--+/g, '-')
			.replace(/^-+/, '')
			.replace(/-+$/, '')
	}

	const checkIfOrderRecapexist = async (amapId) => {
		const selectedSession =
			selectedMonth.getFullYear().toString() +
			('0' + (selectedMonth.getMonth() + 1)).slice(-2)
		dispatch({ type: 'LOADING' })
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}
			await axios.post(
				`${process.env.REACT_APP_API_URL}/api/orders/recaps/update`,
				{ amap: amapId, session: selectedSession },
				config
			)
			setAmap(amapId)
			dispatch({ type: 'FINISHED_LOADING' })
		} catch (error) {
			dispatch({
				type: 'MESSAGE',
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
				messageType: 'error',
			})
			setAddBatchOrder(false)
			dispatch({ type: 'FINISHED_LOADING' })
		}
	}

	const sendOneOrder = async (order) => {
		const selectedSession =
			selectedMonth.getFullYear().toString() +
			('0' + (selectedMonth.getMonth() + 1)).slice(-2)
		const detailsToSend = products.map((product) => {
			return {
				product: product._id,
				quantity: parseInt(order[slugify(product.title)]) || 0,
			}
		})
		const body = {
			clientSlug: slugify(amap + '-' + order.nom + order.prenom),
			clientName: `${order.nom} ${order.prenom}`,
			details: detailsToSend,
			amap: amap,
			session: selectedSession,
		}
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			}

			const { data } = await axios.post(
				`${process.env.REACT_APP_API_URL}/api/orders/backoffice`,
				body,
				config
			)

			return data
		} catch (error) {
			return { error }
		}
	}

	const startSending = async () => {
		const newOrders = []
		dispatch({ type: 'LOADING' })
		const errors = []
		let index = 0
		setSending(true)
		for (const order of data) {
			index++
			const newOrder = await sendOneOrder(order)
			setProgress(index)
			if (newOrder.error) {
				errors.push(newOrder.error)
			} else {
				newOrders.push(newOrder)
			}
		}
		setSending(false)
		dispatch({ type: 'FINISHED_LOADING' })
		console.log(errors)
		if (errors.length > 0) {
			dispatch({
				type: 'MESSAGE',
				payload: "Une erreur s'est produite...",
				messageType: 'error',
			})
			setAddBatchOrder(false)
			return
		} else {
			dispatch({
				type: 'MESSAGE',
				payload: 'Liste de commandes enregistrées avec succés !',
				messageType: 'success',
			})
			setOrders([...orders, ...newOrders])
			setAddBatchOrder(false)
		}
	}

	return (
		<div
			className='flex column container'
			style={{
				marginTop: '1em',
				alignItems: 'center',
			}}
		>
			<button
				className='button action'
				style={{
					fontSize: '2em',
					margin: 'unset',
					alignSelf: 'baseline',
				}}
				onClick={() => setDisplayInfo(!displayInfo)}
			>
				<BiInfoCircle />
			</button>
			{displayInfo && (
				<div
					className='modal flex column container'
					style={{
						marginTop: '1em',
						alignItems: 'center',
					}}
				>
					<button
						className='button action'
						style={{
							fontSize: '2em',
							margin: 'unset',
							alignSelf: 'baseline',
						}}
						onClick={() => setDisplayInfo(!displayInfo)}
					>
						<GrFormClose />
					</button>
					<h1 style={{ margin: '5px auto' }}>Informations</h1>
					<p style={{ textAlign: 'center' }}>
						Avant de pouvoir charger un fichier, selectionnez
						d'abord une amap.
						<br />
						Si elle n'existe pas dans la base il faudra d'abord
						l'ajouter <a href='/amaps'>ici</a>
					</p>
					<p style={{ textAlign: 'center' }}>
						Cet import s'attend à recevoir un fichier au format .csv
						<br />
						Pour savoir comment exporter un tableau au format csv
						avec Libre Office, cliquez{' '}
						<a
							href='https://help.libreoffice.org/latest/fr/text/scalc/guide/csv_files.html'
							target='_blank'
							rel='noopener noreferrer'
						>
							ce lien
						</a>{' '}
					</p>
					<h2 className='danger' style={{ padding: '0 1em' }}>
						Les colonnes attendues et leur titre :
					</h2>
					<table>
						<thead>
							<tr>
								<td>nom</td>
								<td>prenom</td>
								<td>oranges</td>
								<td>pomelos</td>
								<td>mangues</td>
								<td>citrons</td>
								<td>mandarines</td>
								<td>avocats</td>
							</tr>
						</thead>
					</table>
					<h3 style={{ textAlign: 'center' }}>
						Tout minuscule, sans accents.
						<br />
						L'ordre n'est pas important
						<br />
						Le script s'occupera de remapper les différent champs.
					</h3>
					<p style={{ textAlign: 'center' }}>
						Une fois chargé, les résultats seront affichés afin de
						les valider avant d'être envoyés au serveur.
					</p>
				</div>
			)}
			{message && <Toaster message={message} type={messageType} />}

			<form
				className='flex'
				style={{
					maxWidth: 'fit-content',
					margin: '0 auto 1em auto',
					justifyContent: 'space-around',
				}}
			>
				<h3 style={{ margin: 'auto' }}>* Amap :</h3>
				<select
					name='amap'
					required
					style={{
						margin: 'auto',
						border: 'none',
						maxWidth: '60%',
					}}
					value={amap}
					onChange={(e) => {
						checkIfOrderRecapexist(e.target.value)
					}}
				>
					<option value='' disabled>
						Choisir une amap
					</option>
					{amaps.map((amap) => {
						return (
							<option value={amap._id} key={amap._id}>
								{amap.name}
							</option>
						)
					})}
				</select>
			</form>
			<div
				className='flex'
				style={{ justifyContent: 'center', gap: '5em' }}
			>
				<button
					className='button action danger'
					onClick={() => {
						setData([])
						setTotals()
						setAmap('')
						setAddBatchOrder(false)
					}}
				>
					Annuler
				</button>
				{amap.length > 0 && data.length > 0 && (
					<button
						className='button action success'
						onClick={startSending}
					>
						Valider
					</button>
				)}
			</div>
			{amap.length > 0 && data.length === 0 && (
				<div style={{ minWidth: '600px' }}>
					<CSVReader
						onUploadAccepted={(results) => {
							if (results.errors.length) {
								console.error(results.errors)
								dispatch({
									type: 'MESSAGE',
									payload:
										"Une erreur s'est produite en chargant le .csv",
									messageType: 'error',
								})
							}
							setData(results.data)
							setTotals(getTotals(results.data))
						}}
						config={{ header: true, skipEmptyLines: true }}
					>
						{({ getRootProps, acceptedFile }) => (
							<>
								<div style={styles.csvReader}>
									<button
										type='button'
										className='button action'
										{...getRootProps()}
										style={styles.browseFile}
									>
										Selectionner un fichier
									</button>
									<div style={styles.acceptedFile}>
										{acceptedFile && acceptedFile.name}
									</div>
								</div>
							</>
						)}
					</CSVReader>
				</div>
			)}

			{loading && <Loader />}
			{sending && data && (
				<div
					className='flex column'
					style={{ alignItems: 'center', width: '300px' }}
				>
					<label htmlFor='sending'>Envois en cours:</label>
					<progress
						id='sending'
						max={data.length}
						value={progress}
						style={{ width: '100%' }}
					/>
				</div>
			)}
			<div>
				{sending || data.length === 0 ? null : (
					<table
						style={{
							width: 'fit-content',
							padding: '20px 0',
							margin: '0 auto',
						}}
					>
						<thead>
							{totals && (
								<tr>
									<th
										style={{
											borderLeft: 'none',
											borderTop: 'none',
											textAlign: 'right',
										}}
									>
										Cumul (en kg)
									</th>
									{products.map((product) => {
										return (
											<th key={`total-${product._id}`}>
												<b>
													{
														totals[
															slugify(
																product.title
															)
														]
													}
												</b>
											</th>
										)
									})}
								</tr>
							)}
							<tr>
								<th>Nom & prénom</th>
								{products.map((product) => {
									return (
										<th key={product._id}>
											<b>{product.title}</b>
										</th>
									)
								})}
							</tr>
						</thead>

						<tbody>
							{data.map((row, index) => {
								return (
									<tr key={`batchRow-${index}`}>
										<td style={{ textAlign: 'left' }}>
											{row.nom} {row.prenom}
										</td>

										{products.map((product, prodIndex) => {
											return (
												<td
													key={`${slugify(
														row.nom + row.prenom
													)}-${index}-${prodIndex}`}
												>
													{
														row[
															slugify(
																product.title
															)
														]
													}
												</td>
											)
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
				)}
			</div>
		</div>
	)
}
const styles = {
	csvReader: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 10,
		alignItems: 'center',
		justifyContent: 'center',
		gap: '2em',
	},
	browseFile: {
		width: '20%',
	},
	acceptedFile: {
		border: '1px solid #ccc',
		height: 45,
		lineHeight: 2.5,
		paddingLeft: 10,
		width: '80%',
	},
}

export default AddBatchOrders
