import { useContext, useEffect, useState } from 'react'
import { store } from '../../../store'
import { useParams } from 'react-router-dom'
import SingleLabel from './SingleLabel'

import axios from 'axios'

import { BiChevronLeft } from 'react-icons/bi'

import Loader from '../../Loader/Loader'
import './labels.css'

const Labels = () => {
	const globalContext = useContext(store)
	const { dispatch } = globalContext
	const { user, loading, products } = globalContext.state
	const [orders, setOrders] = useState([])
	const [amapDetails, setAmapDetails] = useState([])

	const { amap, session } = useParams()

	useEffect(() => {
		const getOrders = async () => {
			dispatch({ type: 'LOADING' })
			try {
				const config = {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
				const { data } = await axios.get(
					`${process.env.REACT_APP_API_URL}/api/orders?session=${session}&amap=${amap}`,
					config
				)
				setOrders(data.allOrders)
				setAmapDetails(data.deliveryDate[0])
				dispatch({ type: 'FINISHED_LOADING' })
			} catch (error) {
				dispatch({
					type: 'MESSAGE',
					payload:
						error.response && error.response.data.message
							? error.response.data.message
							: error.message,
					messageType: 'error',
				})
				dispatch({ type: 'FINISHED_LOADING' })
			}
		}
		getOrders()
	}, [dispatch, user.token, session, amap])

	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				amapDetails.amap !== undefined && (
					<>
						<a
							href='/'
							className='noPrint'
							style={{
								marginLeft: '2em',
								textDecoration: 'none',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<BiChevronLeft style={{ fontSize: '2em' }} /> Retour
						</a>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '2em',
							}}
						>
							<h2 style={{ margin: 'unset' }}>
								{amapDetails.amap.name}
							</h2>
							<h2 style={{ margin: 'unset' }}>-</h2>
							{amapDetails.delivery !== undefined ? (
								<h2 style={{ margin: 'unset' }}>
									Livraison le{' '}
									{new Intl.DateTimeFormat('fr-FR').format(
										new Date(amapDetails.delivery)
									)}{' '}
									à {amapDetails.amap.deliveryTime}
								</h2>
							) : (
								<em>Aucune date de livraison trouvée...</em>
							)}
						</div>
						<div className='tablesContainer'>
							{orders.map((order) => {
								return (
									<SingleLabel
										key={order._id}
										order={order}
										products={products}
									/>
								)
							})}
						</div>
					</>
				)
			)}
			{orders.length === 0 && (
				<h3 style={{ textAlign: 'center' }}>
					Aucune commande trouvée ...
				</h3>
			)}
		</div>
	)
}

export default Labels
